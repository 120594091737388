import Head from "next/head";

import ErrorPage from "components/ErrorPage";

const Custom404 = () => (
  <>
    <Head>
      <title>404 | Leafly</title>
    </Head>
    <ErrorPage
      initialStrainSlug="lost-soul"
      message="We can't seem to find the page you're looking for."
      statusCode={404}
    />
  </>
);

export default Custom404;
