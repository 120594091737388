import consumerApi from "api/services/consumer-api";
import { StrainV2 } from "custom-types/StrainV2";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

const getStrain = async (strainSlug: string) => {
  if (!strainSlug) {
    return;
  }

  try {
    const { data: strain } = await consumerApi.get<StrainV2 | undefined>(
      `api/strains/v2/${strainSlug}`,
    );

    if (!strain) {
      return;
    }

    strain.highlightedPhotos &&
      strain.highlightedPhotos.length &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      strain.highlightedPhotos.forEach((photo: any, i: number) => {
        if (photo.imageUrl) {
          strain.highlightedPhotos[i].imageUrl = formatImgixUrl(photo.imageUrl);
        }
      });

    if (strain.nugImage) {
      strain.nugImage = formatImgixUrl(strain.nugImage);
    }
    if (strain.award && strain.award.imageUrl) {
      strain.award.imageUrl = formatImgixUrl(strain.award.imageUrl);
    }

    return strain;
  } catch (e) {
    // 404 is expected if the strain doesn't exist
    if (e.statusCode !== 404) {
      logError(e.message, {
        functionName: "getStrain",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return;
  }
};

export default getStrain;
