import { useEffect, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import Link from "next/link";

import getStrain from "api/requests/getStrain";
import { Strain } from "custom-types/Strain";

import RetakeIcon from "components/Icons/refresh.svg";
import Shapes from "components/Icons/shapes.svg";
import Image from "components/Image";

const LINKS = [
  {
    name: "Homepage",
    url: "/",
  },
  {
    name: "Dispensaries",
    url: "/dispensaries",
  },
  { name: "Deals", url: "/deals" },
  { name: "Strains", url: "/strains" },
  { name: "Products", url: "/products" },
  { name: "Contact us", url: "/info/contact" },
];

type ErrorPageProps = {
  initialStrainSlug?: string;
  statusCode: number;
  message: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  initialStrainSlug,
  statusCode,
  message,
}) => {
  const [spotlightStrain, setSpotlightStrain] = useState<Strain | undefined>();

  const getRandomStrain = async () => {
    const { data: randomStrain } =
      await axios.get<Strain>("/api/strain/random");

    setSpotlightStrain(randomStrain);
  };

  useEffect(() => {
    const strainRequest = initialStrainSlug
      ? getStrain(initialStrainSlug)
      : axios.get<Strain>("/api/strain/random").then(({ data }) => data);

    strainRequest.then((strain) => setSpotlightStrain(strain));
  }, []);

  return (
    <div className="container my-8 md:my-16 flex flex-col md:flex-row flex-wrap">
      <div className="col md:col-1/2 mb-lg">
        <h1 className="text-[72px] font-extrabold mb-md">Oops!</h1>
        <p className="text-md mb-xl">{message}</p>
        <p className="font-bold">Error code: {statusCode}</p>
      </div>

      <div className="col md:col-1/2 relative">
        <div className="p-6 md:p-12 relative z-10">
          <div className="relative">
            {[...Array(2)].map((_e, i) => (
              <div
                className={classNames(
                  "absolute bg-white shadow-low rounded-[20px] w-full h-full",
                  {
                    "-rotate-3": i === 1,
                    "rotate-3": i === 0,
                  },
                )}
                key={i}
              />
            ))}
            <div className="bg-white shadow-low rounded-[20px] w-full relative p-lg h-[380px]">
              {spotlightStrain && (
                <>
                  <h2 className="relative text-center mb-md">
                    {spotlightStrain.name}
                  </h2>
                  <div className="w-full max-w-[250px] mx-auto mb-md">
                    <Image
                      alt={`Nug image for ${spotlightStrain.name}`}
                      src={spotlightStrain.nugImage || ""}
                      disableLazyLoad={true}
                      sizes={[250]}
                      ratio="square"
                    />
                  </div>

                  <Link
                    className="mx-auto button"
                    href={`/strains/${spotlightStrain.slug}`}
                  >
                    see strain
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>

        <button
          onClick={getRandomStrain}
          className="flex items-center justify-center mx-auto -mt-lg md:-mt-xxl p-lg z-10 relative"
        >
          <RetakeIcon height="14" width="14" />
          <span className="block ml-sm underline text-xs leading-none">
            see another strain
          </span>
        </button>
        <Shapes className="absolute top-0 z-0" />
      </div>

      <div className="col md:col-1/2 mt-md md:-mt-64">
        <p>Here are some helpful links instead:</p>
        <ul>
          {LINKS.map((link) => (
            <li
              key={link.name}
              className="underline font-bold text-green mb-sm"
            >
              <Link href={link.url}>{link.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ErrorPage;
